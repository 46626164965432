export const recordOperationOptions = {
  anesthesia: [
    { value: 'ga', label: 'G/A' },
    { value: 'ivg', label: 'IVG' },
    { value: 'la', label: 'L/A' }
  ],
  posture: [
    { value: 'lie', label: '平躺' },
    { value: 'prone', label: '俯臥' },
    { value: 'lithotomy', label: '截石位' },
    { value: 'other', label: '其他', other: 'postureOther' }
  ],
  electrodePatch: [
    { value: 'n', label: '無' },
    { value: 'y', label: '有' }
  ],
  electrodePosition: [
    { value: 'n', label: '無' },
    { value: 'abdomen', label: '腹部' },
    { value: 'thigh', label: '大腿' },
    { value: 'calf', label: '小腿' },
    { value: 'lower', label: '後腰' }
  ],
  quantityVerification: [
    { value: 'y', label: '是' },
    { value: 'n', label: '否' }
  ],
  indwellingNeedle: [
    { value: '20', label: '20Fr' },
    { value: '22', label: '22Fr' },
    { value: '24', label: '24Fr' }
  ],
  iv: [
    { value: 'ns', label: 'N/S 500cc' },
    { value: 'lr', label: 'L/R 500cc' },
    { value: 'd5s', label: 'D5S 500cc' },
    { value: 'd5w', label: 'D5W 500cc' },
    { value: 'hes', label: '代用血漿' },
    { value: 'other', label: '其他', other: 'ivOther' }
  ],
  injection: [
    { value: 'cefa1g', label: 'Cefa 1g' },
    { value: '80mg', label: 'GM 80mg' },
    { value: 'vitc', label: 'Vit C 1 amp' },
    { value: 'decan', label: 'Decan 1 amp' },
    { value: 'transamin', label: 'Transamin 500mg' },
    { value: 'other', label: '其他', other: 'injectionOther' }
  ],
  disinfectant: [
    { value: 'aqua_easy', label: '克菌寧' },
    { value: 'alcoholic', label: '酒優' },
    { value: 'water', label: '水優' }
  ],
  sutures: [
    { value: 'nylon', label: 'Nylon-', other: 'suturesNylon' },
    { value: 'vicryl', label: 'Vicryl-', other: 'suturesVicryl' },
    { value: 'euTek', label: 'EU-TEK-', other: 'suturesEuTek' },
    { value: 'pds', label: 'PDS-', other: 'suturesPds' },
    { value: 'other', label: '其他', other: 'suturesOther' }
  ],
  drainage: [
    { value: 'n', label: '無' },
    { value: 'penrose', label: 'Penrose' },
    { value: 'jp', label: 'JP drain', other: 'drainageJp' },
    { value: 'other', label: '其他', other: 'drainageOther' }
  ],
  specimen: [
    { value: 'n', label: '無' },
    { value: 'culture', label: 'culture' },
    { value: 'section', label: '病理切片', other: 'specimenOther' }
  ],
  specimenCulture: [
    { value: 'blue', label: '嗜氧(藍)' },
    { value: 'black', label: '厭氧(黑)' }
  ],
  adhesive: [
    { value: 'n', label: '無' },
    { value: 'dermabond', label: 'dermabond' },
    { value: 'sincerehealth', label: '百達' },
    { value: 'tissueGlue', label: '組織膠' }
  ],
  oppressor: [
    { value: 'n', label: '無' },
    { value: 'noseMold', label: '鼻模' },
    { value: 'chestCompressionStrap', label: '壓胸帶' },
    { value: 'hood', label: '頭套' },
    { value: 'positioningGarment', label: '定位衣' },
    { value: 'shapingGarments', label: '塑身衣' },
    { value: 'other', label: '其他', other: 'oppressorOther' }
  ],
  oppressorShapingGarments: [
    { value: 'body', label: '塑衣' },
    { value: 'arm', label: '塑臂' },
    { value: 'waist', label: '塑腹帶' },
    { value: 'leg', label: '塑褲' }
  ],
  woundDressing: [
    { value: 'tetracycline', label: '四環黴素' },
    { value: 'neomycin', label: 'Neomycin' },
    { value: 'gm', label: 'GM' },
    { value: 'artificialSkin', label: '人工皮' },
    { value: 'beautyGlue', label: '美容膠' },
    { value: 'gauze', label: '紗布' },
    { value: 'opSite', label: 'OP site' },
    { value: '3mMaskingTape', label: '3M紙膠' },
    { value: 'other', label: '其他', other: 'woundDressingOther' }
  ],
  foley: [
    { value: 'n', label: '無' },
    { value: 'y', label: 'Fr.foley' }
  ],
  selfCatheterization: [
    { value: 'n', label: '無' },
    { value: 'y', label: 'Fr.foley' }
  ],
  infusionVolume: [
    { value: 'leftFrontThigh', label: '左前大腿' },
    { value: 'leftHindThigh', label: '左後大腿' },
    { value: 'rightFrontThigh', label: '右前大腿' },
    { value: 'rightHindThigh', label: '右後大腿' },
    { value: 'leftFrontArm', label: '左前手臂' },
    { value: 'leftHindArm', label: '左後手臂' },
    { value: 'rightFrontArm', label: '右前手臂' },
    { value: 'rightHindArm', label: '右後手臂' },
    { value: 'frontAbdomen', label: '前腹部' },
    { value: 'hindAbdomen', label: '後腹部' },
    { value: 'doubleChin', label: '雙下巴' }
  ],
  liposuctionVolume: [
    { value: 'leftFrontThigh', label: '左前大腿' },
    { value: 'leftHindThigh', label: '左後大腿' },
    { value: 'rightFrontThigh', label: '右前大腿' },
    { value: 'rightHindThigh', label: '右後大腿' },
    { value: 'leftFrontArm', label: '左前手臂' },
    { value: 'leftHindArm', label: '左後手臂' },
    { value: 'rightFrontArm', label: '右前手臂' },
    { value: 'rightHindArm', label: '右後手臂' },
    { value: 'frontAbdomen', label: '前腹部' },
    { value: 'hindAbdomen', label: '後腹部' },
    { value: 'doubleChin', label: '雙下巴' }
  ]
}
