import "core-js/modules/es.array.concat.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["signature-board", [$props.readonlySrc ? 'signature-board_readoly' : null]]),
    ref: "refRoot",
    style: _normalizeStyle("width:".concat($props.width, "; height: ").concat($props.height))
  }, [$props.readonlySrc ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $props.readonlySrc,
    alt: "",
    class: "signature-board__readonly-img"
  }, null, 8, _hoisted_1)) : _createCommentVNode("", true)], 6);
}