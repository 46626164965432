/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/SignatureModal.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0eed061e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = {
  key: 1
};
export default {
  __name: 'signature-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var state = reactive({
      visible: false
    });

    function onConfirm(e) {
      state.visible = false;
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        sign: e
      }));
    }

    return function (_ctx, _cache) {
      var _component_SignatureModal = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", {
        class: "sign-block",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _unref(state).visible = true;
        })
      }, [__props.value.sign ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "sign-preview",
        src: __props.value.sign,
        alt: ""
      }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, "簽名欄位")), _createVNode(_component_SignatureModal, {
        visible: _unref(state).visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).visible = $event;
        }),
        onConfirm: onConfirm
      }, null, 8, ["visible"])]);
    };
  }
};