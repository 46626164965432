/* unplugin-vue-components disabled */import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/MUploader.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MFsLightbox.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'upload-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var state = reactive({
      lightboxShow: false,
      slideIdx: 0,
      medias: [],
      dimension: {
        height: 0,
        width: 0
      }
    });

    function calMaxImages(imageWidth) {
      var maxCols = Math.floor(state.dimension.width / (imageWidth + 10));
      var maxRows = Math.floor(state.dimension.height / (imageWidth + 10));
      return maxCols * maxRows;
    }

    var imageWidth = computed(function () {
      var imageWidth = 80;

      while (props.value.images.length + 1 > calMaxImages(imageWidth)) {
        imageWidth -= 1;
      }

      return imageWidth;
    });

    function upload(e) {
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        images: e
      }));
    }

    function viewMedia(evt) {
      state.medias = evt.values.map(function (item) {
        return item.full_path;
      });
      state.lightboxShow = true;
      state.slideIdx = evt.index + 1;
    }

    var uploadRef = ref();
    onMounted(function () {
      state.dimension.height = uploadRef.value.clientHeight;
      state.dimension.width = uploadRef.value.clientWidth;
    });
    return function (_ctx, _cache) {
      var _component_MFsLightbox = __unplugin_components_0;

      var _component_MUploader = __unplugin_components_1;

      return _openBlock(), _createElementBlock("div", {
        ref_key: "uploadRef",
        ref: uploadRef,
        class: "upload-block"
      }, [(_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createVNode(_component_MFsLightbox, {
        modelValue: _unref(state).lightboxShow,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).lightboxShow = $event;
        }),
        sources: _unref(state).medias
      }, null, 8, ["modelValue", "sources"])])), _createVNode(_component_MUploader, {
        "model-value": __props.value.images,
        accept: ".jpg,.png,.jpeg,.webp",
        style: _normalizeStyle({
          '--m-base-uploader-item-width': _unref(imageWidth) + 'px'
        }),
        "onUpdate:modelValue": upload,
        onItemClick: viewMedia
      }, null, 8, ["model-value", "style"])], 512);
    };
  }
};