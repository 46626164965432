/* unplugin-vue-components disabled */import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/MDropdownMenu.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/MTimePicker.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/form/MInput.vue';
import "core-js/modules/es.array.join.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "width": "250px",
    "box-shadow": "0px 0px 70px rgba(0, 0, 0, 0.2)",
    "border-radius": "5px",
    "overflow": "hidden",
    "margin-top": "10px"
  }
};
import moment from 'moment';
export default {
  __name: 'DropdownMenuTimePicker',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    bg: {
      type: String,
      default: '#ECECEC'
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var value = computed({
      /** @type {any} */
      get: function get() {
        return props.modelValue;
      },
      set: function set(value) {
        return emit('update:modelValue', value);
      }
    });
    var time = computed(function () {
      if (Array.isArray(value.value)) {
        var momentTime = value.value.join(':');
        return moment(momentTime, 'H:m').format('HH:mm');
      }

      return value.value;
    });
    return function (_ctx, _cache) {
      var _component_MInput = __unplugin_components_0;

      var _component_MTimePicker = __unplugin_components_1;

      var _component_MDropdownMenu = __unplugin_components_2;

      return _openBlock(), _createBlock(_component_MDropdownMenu, null, {
        menu: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MTimePicker, {
            modelValue: _unref(value),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _isRef(value) ? value.value = $event : null;
            })
          }, null, 8, ["modelValue"])])];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("div", null, [_createVNode(_component_MInput, {
            placeholder: "請輸入",
            bg: __props.bg,
            "model-value": _unref(time),
            readonly: ""
          }, null, 8, ["bg", "model-value"])])];
        }),
        _: 1
      });
    };
  }
};