/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/dragged-form/doodle-modal.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1aa910fc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
export default {
  __name: 'doodle-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var state = reactive({
      visible: false
    });

    function onConfirm(e) {
      state.visible = false;
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        doodle: e
      }));
    }

    return function (_ctx, _cache) {
      var _props$value;

      var _component_DoodleModal = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", {
        class: "doodle-block",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _unref(state).visible = true;
        })
      }, [__props.value.doodle ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "preview",
        src: __props.value.doodle,
        alt: ""
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createVNode(_component_DoodleModal, {
        visible: _unref(state).visible,
        images: (_props$value = __props.value) === null || _props$value === void 0 ? void 0 : _props$value.images,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).visible = $event;
        }),
        onConfirm: onConfirm
      }, null, 8, ["visible", "images"])]);
    };
  }
};