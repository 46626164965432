import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5f784483"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "radio-block"
};
var _hoisted_2 = ["checked", "name", "value"];
var _hoisted_3 = ["id"];
export default {
  __name: 'radio-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;

    function updateValue(e) {
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        selected: e.target.value
      }));
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.value.options, function (option) {
        return _openBlock(), _createElementBlock("label", {
          key: option.id,
          class: "radio-block-option"
        }, [_createElementVNode("input", {
          checked: __props.value.selected === option.text,
          name: _ctx.$attrs.id,
          type: "radio",
          value: option.text,
          onChange: updateValue
        }, null, 40, _hoisted_2), _createElementVNode("span", {
          id: option.id
        }, _toDisplayString(option.text), 9, _hoisted_3)]);
      }), 128))])]);
    };
  }
};